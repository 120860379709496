import { useState } from "react";
import { StyledImageGallery } from "../styles";
import { IKImage } from "imagekitio-react";
import FullSizeImage, { exampleImage, ImageType } from "./FullSizeImage";

const ImageGallery = ({ images }: { images: ImageType[] | undefined }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<ImageType>(exampleImage);
  const handleClick = (item: ImageType) => {
    setItem(item);
    setOpen(!open);
  };

  return (
    <StyledImageGallery>
      {images?.map((item: ImageType, i: number) => (
        <>
          <IKImage
            key={i}
            alt={item.altText}
            urlEndpoint={`https://ik.imagekit.io/amythia/images`}
            path={item.image}
            lqip={{ active: true }}
            loading="lazy"
            // Temp media query fix for thicc images
            style={{
              display: `${item.thicc && window.innerWidth < 600 && "none"}`,
            }}
            onClick={() => handleClick(item)}
          />
        </>
      ))}
      <FullSizeImage
        open={open}
        theImage={item.image}
        altText={item.altText}
        index={1}
        setOpen={setOpen}
      />
    </StyledImageGallery>
  );
};

export default ImageGallery;
