import CustomCard from "../components/CustomCard";
import Carousel from "../components/Carousel";
import {
  imageCollectionStrings,
  SFWsketchStrings,
  SFWpaintStrings,
} from "../images";
import {
  StyledRowCardGroup,
  StyledDrawerSpacer,
  StyledFlexin,
} from "../styles";

const {
  AVAH_PORTRAIT,
  DELILAH_SKETCH,
  DON_POSTER_SKETCH,
  MESSY_GIRL_COMPLETE,
  ALURIA_HALF_TEXTURE,
  DUSTER_SKETCH_WHITE,
} = imageCollectionStrings;

const {
  EVERETT_SPELL,
  ALPHA_DOG,
  ALEX_SKETCH,
  SPACECAMP_ASHERUTE,
  GIRL_PRACTICE_SKETCH,
  LILY_STATUE,
} = SFWsketchStrings;

const { LOVETA_DRESS, MORNING_MARKET_CHASE } = SFWpaintStrings;

const CommissionPrices = () => (
  <StyledDrawerSpacer>
    <StyledRowCardGroup>
      <StyledFlexin>
        <CustomCard
          title="Sketch"
          content={["Starting at $40"]}
          images={[GIRL_PRACTICE_SKETCH]}
        />
        <CustomCard
          title="Light paint"
          content={["Starting at $100"]}
          images={[LOVETA_DRESS]}
        />
      </StyledFlexin>
      <CustomCard
        title="Full paint"
        content={["Starting at $200"]}
        orientation="landscape"
        imageLocation="right"
        images={[MORNING_MARKET_CHASE]}
      />
      <Carousel
        images={[
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
        ]}
      />
    </StyledRowCardGroup>
  </StyledDrawerSpacer>
);

export default CommissionPrices;
