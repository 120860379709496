import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledRowCardGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;

  p {
    margin: 16px 0;
    font-size: 0.95rem;
    text-align: center;
  }
`;

export const StyledFlexin = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100vw;

  .MuiPaper-root {
    margin: 0 12px;
  }
`;

export const StyledSplitCardGroup = styled(StyledRowCardGroup)`
  width: 40%;
`;

export const TOSpageWrapper = styled.div`
  margin: 40px 28px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 8px 0 0 8px;
`;

interface StyledCarouselProps {
  maxHeight?: string;
};

export const StyledCarousel = styled.div<StyledCarouselProps>`
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-75%);
    }
  }
  overflow: hidden;

  .slide-track {
    animation: scroll 60s linear infinite;
    display: flex;
    width: calc(${props => props.maxHeight}px * 16);
    max-height: ${props => props.maxHeight}px;
    height: ${props => props.maxHeight}px;

    &:hover {
      animation-play-state: paused;
    }
  }
`;

export const StyledImageGallery = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    height: 640px;
    margin: 8px;
    border: 2px solid gray;
  }
`;

export const StyledDrawerSpacer = styled.div`
  margin-left: 192px;
  margin-top: 40px;
  height: 100vh;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;
