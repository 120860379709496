import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IKImage } from "imagekitio-react";
import styled from "@emotion/styled";

export type ImageType = {
  image: string;
  altText: string;
  thicc?: boolean | undefined;
};

const StyledDialogTitle = styled(DialogTitle)`
  background-color: lightskyblue;
  color: rgb(84, 84, 84);
  font-size: 1.5em;
  position: sticky;
  top: 0;
`;

const StyledButton = styled(Button)`
  font-size: 1.1em;
  margin-bottom: 4px;
  margin-right: 12px;
`;

export const exampleImage = {
  image: "sfw/sketches/yumiSketch.jpg",
  altText: "yumiSketch",
};

const FullSizeImage = ({
  open,
  theImage,
  altText,
  index,
  setOpen,
}: {
  open: boolean;
  theImage: string;
  altText: string;
  index: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
      <StyledDialogTitle id="responsive-dialog-title">
        <StyledButton onClick={() => setOpen(false)}>{"X"}</StyledButton>
        {"Return to image gallery"}
      </StyledDialogTitle>
      <IKImage
        key={index}
        alt={altText}
        urlEndpoint={`https://ik.imagekit.io/amythia/images`}
        path={theImage}
        lqip={{ active: true }}
        loading="lazy"
      />
    </Dialog>
  );
};

export default FullSizeImage;
