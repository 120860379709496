import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Carousel from "./Carousel";
import { IKImage } from "imagekitio-react";
import { imageCollectionStrings } from "../images";
import { StyledRowCardGroup, StyledSplitCardGroup } from "../styles";
import FullSizeImage, { exampleImage, ImageType } from "./FullSizeImage";

export default function CustomCard({
  images,
  title,
  leftSubTitle,
  rightSubTitle,
  content,
  splitContent,
  primaryButtonText,
  secondaryButtonText,
  cardWidth,
  imageHeight,
  orientation = "portrait",
  imageLocation = "left",
  backgroundColor,
  banner,
  scrollingImage,
}: {
  images?: string[];
  title?: string;
  leftSubTitle?: string;
  rightSubTitle?: string;
  content?: string[];
  splitContent?: string[];
  primaryButtonText?: string;
  secondaryButtonText?: string;
  cardWidth?: number | string;
  imageHeight?: string;
  orientation?: "landscape" | "portrait";
  imageLocation?: "top" | "bottom" | "left" | "right";
  backgroundColor?: string;
  banner?: boolean;
  scrollingImage?: boolean;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<ImageType>(exampleImage);
  const handleClick = (item: string, altText: string) => {
    setItem({ image: item, altText });
    setOpen(!open);
  };
  const portraitMode = orientation === "portrait";
  const imagesConfirmed = images && images[0];

  const cardImage = scrollingImage ? (
    <Carousel images={images} maxHeight="280" />
  ) : (
    <>
      <IKImage
        urlEndpoint={`https://ik.imagekit.io/amythia/images`}
        path={imagesConfirmed || imageCollectionStrings.RANOA_ICON}
        height={imageHeight || "280"}
        width={"100%"}
        lqip={{ active: true }}
        onClick={() =>
          handleClick(
            imagesConfirmed || imageCollectionStrings.RANOA_ICON,
            "Art by Ranoa"
          )
        }
      />
      <FullSizeImage
        open={open}
        theImage={item.image}
        altText={item.altText}
        index={1}
        setOpen={setOpen}
      />
    </>
  );

  return (
    <Card
      sx={{
        maxWidth: cardWidth || (portraitMode ? 240 : 700),
        width: cardWidth,
        backgroundColor: backgroundColor || "whiteSmoke",
      }}
      style={{ marginBottom: "28px" }}
    >
      <div style={portraitMode ? {} : { display: "flex" }}>
        {(imageLocation === "left" || imageLocation === "top") &&
          imagesConfirmed !== "" &&
          cardImage}

        <CardContent>
          <Typography
            gutterBottom
            variant={banner ? "h4" : "h5"}
            component="div"
          >
            {title ?? "Placeholder Title"}
          </Typography>
          {banner ? (
            <Typography style={{ margin: "-20px" }} />
          ) : splitContent ? (
            <StyledRowCardGroup>
              <StyledSplitCardGroup>
                <Typography gutterBottom variant="h6" component="div">
                  {leftSubTitle ?? "Placeholder Title"}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {content && content.length > 0
                    ? content.map((line, i) => <p key={i}>{line}</p>)
                    : "Placeholder Content"}
                </Typography>
              </StyledSplitCardGroup>
              <Divider orientation="vertical" variant="middle" flexItem />
              <StyledSplitCardGroup>
                <Typography gutterBottom variant="h6" component="div">
                  {rightSubTitle ?? "Placeholder Title"}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {splitContent && splitContent.length > 0
                    ? splitContent.map((line, i) => <p key={i}>{line}</p>)
                    : "Placeholder Content"}
                </Typography>
              </StyledSplitCardGroup>
            </StyledRowCardGroup>
          ) : (
            <Typography component="span" variant="body2" color="text.secondary">
              {content && content.length > 0
                ? content.map((line, i) => <p key={i}>{line}</p>)
                : "Placeholder Content"}
            </Typography>
          )}
        </CardContent>

        {(imageLocation === "right" || imageLocation === "bottom") &&
          imagesConfirmed !== "" &&
          cardImage}

        {primaryButtonText && secondaryButtonText && (
          <CardActions>
            {primaryButtonText || (
              <Button size="small">{primaryButtonText}</Button>
            )}
            {secondaryButtonText || (
              <Button size="small">{secondaryButtonText}</Button>
            )}
          </CardActions>
        )}
      </div>
    </Card>
  );
}
