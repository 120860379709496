import { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ImageGallery from "../components/ImageGallery";
import {
  imageCollectionStrings,
  NSFWsketchStrings,
  NSFWpaintStrings,
} from "../images";
import { StyledDrawerSpacer } from "../styles";

const {
  ASTRID_SKETCH,
  FLOOFY_COLORED_LINEART,
  FOUREN_PAINT,
  HEATHER_FIRETAIL_SKETCH,
  KOTA_DEITER,
  LOVE_ARIELLE_SKETCH,
} = imageCollectionStrings;

const {
  asterTricoSketch,
  haneVersions,
  jacuzziSketch,
  tricoHammerSketch,
  spidergirl,
  saniyaTricoSketch,
  alphadogSketch,
  goldenWoofer2,
  megaDragonessBonerCoom,
  megaDragonessPussy,
  megaDragonessCock,
  donderDickbulge,
  angelaGenderbendBoxers,
} = NSFWsketchStrings;

const {
  jiangSamanyaConditioning,
  penelopeOutdoorSpeedpaint,
  goldieSauna,
  hanakoPainting,
  jiangSamanyaSlaveTouchup,
  quattroAndrewPaint,
  zaneTanukiBulge,
} = NSFWpaintStrings;

const imageMap = [
  { image: asterTricoSketch, altText: "asterTricoSketch" },
  { image: tricoHammerSketch, altText: "tricoHammerSketch" },
  { image: saniyaTricoSketch, altText: "saniyaTricoSketch" },
  { image: goldenWoofer2, altText: "goldenWoofer2" },
  {
    image: megaDragonessBonerCoom,
    altText: "megaDragonessBonerCoom",
    thicc: true,
  },
  { image: megaDragonessPussy, altText: "megaDragonessPussy", thicc: true },
  { image: megaDragonessCock, altText: "megaDragonessCock", thicc: true },
  { image: donderDickbulge, altText: "donderDickbulge" },
  { image: angelaGenderbendBoxers, altText: "angelaGenderbendBoxers" },
  { image: jiangSamanyaConditioning, altText: "jiangSamanyaConditioning" },
  { image: penelopeOutdoorSpeedpaint, altText: "penelopeOutdoorSpeedpaint" },
  { image: goldieSauna, altText: "goldieSauna" },
  { image: hanakoPainting, altText: "hanakoPainting" },
  { image: jiangSamanyaSlaveTouchup, altText: "jiangSamanyaSlaveTouchup" },
  { image: zaneTanukiBulge, altText: "zaneTanukiBulge" },
  { image: ASTRID_SKETCH, altText: "AstridSketch" },
  { image: FOUREN_PAINT, altText: "Fuoren_2_paint" },
  { image: HEATHER_FIRETAIL_SKETCH, altText: "Heather_Firetail_sketch" },
  { image: KOTA_DEITER, altText: "Kota_Deiter_1" },
  { image: LOVE_ARIELLE_SKETCH, altText: "LovetaAreilleSketch" },
  {
    image: FLOOFY_COLORED_LINEART,
    altText: "Floofy_colored_lineart",
    thicc: true,
  },
  { image: quattroAndrewPaint, altText: "quattroAndrewPaint", thicc: true },
  { image: alphadogSketch, altText: "alphadogSketch", thicc: true },
  { image: haneVersions, altText: "haneVersions", thicc: true },
  { image: jacuzziSketch, altText: "jacuzziSketch", thicc: true },
  { image: spidergirl, altText: "spidergirl", thicc: true },
];

const NSFWSampleGallery = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleConfirm = () => {
    localStorage.setItem("visited", "true");
    setShowDialog(false);
  };

  const renderDialog = useCallback(
    () => (
      <Dialog open={showDialog} sx={{ backgroundColor: "rgba(0, 0, 0, 100%)" }}>
        <DialogTitle>Ranoa's NSFW Sample Gallery</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following content is explicit, are you at least 18 years of age?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>I am a legal adult</Button>
        </DialogActions>
      </Dialog>
    ),
    [showDialog]
  );

  const visited = localStorage.getItem("visited");

  useEffect(() => {
    if (!visited) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [visited]);

  return (
    <StyledDrawerSpacer>
      <ImageGallery images={imageMap} />;{showDialog && renderDialog()}
    </StyledDrawerSpacer>
  );
};

export default NSFWSampleGallery;
