import CustomCard from "../components/CustomCard";
import {
  imageCollectionStrings,
  SFWsketchStrings,
  SFWpaintStrings,
} from "../images";
import {
  StyledRowCardGroup,
  TOSpageWrapper,
  StyledDrawerSpacer,
} from "../styles";

const {
  AVAH_PORTRAIT,
  DELILAH_SKETCH,
  DON_POSTER_SKETCH,
  MESSY_GIRL_COMPLETE,
  ALURIA_HALF_TEXTURE,
  DUSTER_SKETCH_WHITE,
} = imageCollectionStrings;

const {
  EVERETT_SPELL,
  ALPHA_DOG,
  ALEX_SKETCH,
  SPACECAMP_ASHERUTE,
  GIRL_PRACTICE_SKETCH,
  LILY_STATUE,
} = SFWsketchStrings;

const { LOVETA_DRESS, MORNING_MARKET_CHASE } = SFWpaintStrings;

const TermsOfService = () => (
  <StyledDrawerSpacer>
    <TOSpageWrapper>
      <CustomCard
        images={[
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
          AVAH_PORTRAIT,
          DELILAH_SKETCH,
          DON_POSTER_SKETCH,
          MESSY_GIRL_COMPLETE,
          ALURIA_HALF_TEXTURE,
          DUSTER_SKETCH_WHITE,
          EVERETT_SPELL,
          ALPHA_DOG,
          ALEX_SKETCH,
          SPACECAMP_ASHERUTE,
          GIRL_PRACTICE_SKETCH,
          LILY_STATUE,
          // paintings
          LOVETA_DRESS,
          MORNING_MARKET_CHASE,
        ]}
        title="Terms of Service"
        content={[""]}
        imageLocation="top"
        cardWidth="100%"
        banner
        scrollingImage
      />

      <StyledRowCardGroup>
        <CustomCard
          images={[""]}
          title="Disclaimer"
          content={[
            "Artist is colorblind! If ordering a color commission, be " +
              "sure to provide color references if you are particular about it. " +
              "Digital painting allows me to generally be accurate without any " +
              "guidance (e.g. I know where green is on the color wheel, so you'll " +
              "never accidentally receive red) but for the best accuracy, please be present for color stages!",
          ]}
          orientation="landscape"
          cardWidth={350}
        />
        <CustomCard
          images={[""]}
          title="Payment"
          content={[
            "• Payment is required in full before beginning any work.",
            "• Full refunds are available before the start of work.",
            "• No refunds upon completion of work.",
            "• All prices listed for commissions are in USD. Payment is available via Paypal only.",
            "• No inquiries regarding free work.",
          ]}
          cardWidth={350}
        />
        <CustomCard
          images={[""]}
          title="Rights"
          content={[
            "• Artist reserves all rights for completed work unless otherwise agreed upon.",
            "• Commissioners may repost and distribute completed work.",
            "• Commissioners may not edit, resell, or otherwise profit from any work unless otherwise agreed upon.",
          ]}
          orientation="landscape"
          cardWidth={350}
        />
      </StyledRowCardGroup>
    </TOSpageWrapper>
  </StyledDrawerSpacer>
);

export default TermsOfService;
