import { useState } from "react";
import { StyledCarousel } from "../styles";
import { IKImage } from "imagekitio-react";
import FullSizeImage, { exampleImage, ImageType } from "./FullSizeImage";

const Carousel = ({
  images,
  maxHeight = "450",
  altText = "Screenshot",
}: {
  images: string[] | undefined;
  maxHeight?: string;
  altText?: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<ImageType>(exampleImage);
  const handleClick = (item: string, altText: string) => {
    setItem({ image: item, altText });
    setOpen(!open);
  };

  return (
    <StyledCarousel maxHeight={maxHeight}>
      <div className="slide-track">
        {images &&
          images.map((element, i) => {
            return (
              <IKImage
                key={i}
                alt={altText}
                urlEndpoint={`https://ik.imagekit.io/amythia/images`}
                path={element}
                height={maxHeight}
                lqip={{ active: true }}
                onClick={() => handleClick(element, altText)}
              />
            );
          })}
      </div>
      <FullSizeImage
        open={open}
        theImage={item.image}
        altText={item.altText}
        index={1}
        setOpen={setOpen}
      />
    </StyledCarousel>
  );
};

export default Carousel;
