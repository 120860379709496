import { StyledDrawerSpacer } from "../styles";

const SubmissionForm = () => (
  <StyledDrawerSpacer>
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeak9Ys-fDs71mvjMEceJ0BiauzfJX9hz3vSEm-n3I__uVxZQ/viewform?embedded=true"
      width="640"
      height="3400"
      title="submission-form"
      frameBorder="0"
      loading="eager"
    >
      Loading…
    </iframe>
  </StyledDrawerSpacer>
);

export default SubmissionForm;
