const PLACEHOLDER = '/placeholder.jpg';
const RANOA_ICON = '/ranoaIcon.jpeg';
const ASTRID_SKETCH = '/nsfw/AstridSketch.jpg';
const FLOOFY_COLORED_LINEART = '/nsfw/Floofy_colored_lineart.jpg';
const FOUREN_PAINT = '/nsfw/Fuoren_2_paint.jpg';
const HEATHER_FIRETAIL_SKETCH = '/nsfw/Heather_Firetail_sketch.jpg';
const KOTA_DEITER = '/nsfw/Kota_Deiter_1.jpg';
const LOVE_ARIELLE_SKETCH = '/nsfw/LovetaAreilleSketch.jpg';
const AVAH_PORTRAIT = '/sfw/Avah_portrait.jpg';
const DELILAH_SKETCH = '/sfw/Delilah_sketch.jpg';
const DON_POSTER_SKETCH = '/sfw/Don_poster_sketch.jpg';
const MESSY_GIRL_COMPLETE = '/sfw/Messy_girl_complete.png';
const ALURIA_HALF_TEXTURE = '/sfw/aluria_half_texture.jpg';
const DUSTER_SKETCH_WHITE = '/sfw/duster_sketch_2_white.jpg';
// SFW sketches
const EVERETT_SPELL = '/sfw/sketches/everettSpell.jpg';
const ALPHA_DOG = '/sfw/sketches/alphadogSketch2.jpg';
const ALEX_SKETCH = '/sfw/sketches/alexSketch.jpg';
const SPACECAMP_ASHERUTE = '/sfw/sketches/spacecampAsherute.jpg';
const GIRL_PRACTICE_SKETCH = '/sfw/sketches/girlPracticeSketch.jpg';
const LILY_STATUE = '/sfw/sketches/lilyStatueSketch.jpg';
const RORI = 'sfw/sketches/roriGiftSketch.jpg';
const evrartSketch = 'sfw/sketches/evrartSketch.jpg';
const IsabelleFiretailColored = 'sfw/sketches/IsabelleFiretailColored.jpg';
const sketchPracticePortraits2 = 'sfw/sketches/sketchPracticePortraits2.jpg';
const spacecampFlasherPlaneSketch = 'sfw/sketches/spacecampFlasherPlaneSketch.jpg';
const yumiSketch = 'sfw/sketches/yumiSketch.jpg';
const towSharkLine = 'sfw/sketches/towSharkLine.jpg';
const goldenWoofer = 'sfw/sketches/goldenWoofer.jpg';
const snowblindWitch = 'sfw/sketches/snowblindWitch.jpg';
const sketchPracticePortrait = 'sfw/sketches/sketchPracticePortrait.jpg';
const lovetaDressSketch = 'sfw/sketches/lovetaDressSketch.jpg';
const IsabelleFiretailSketch = 'sfw/sketches/IsabelleFiretailSketch.jpg';
const cowboySketch = 'sfw/sketches/cowboySketch.jpg';
// SFW paintings
const LOVETA_DRESS = 'sfw/paintings/lovetaDress.jpg';
const MORNING_MARKET_CHASE = 'sfw/paintings/morningMarketChase.jpg';
const kotaRainPawPaint = 'sfw/paintings/kotaRainPawPaint.png';
const glowMoff = 'sfw/paintings/glowMoff.jpg';
const spacecampFlasher = 'sfw/paintings/spacecampFlasher.jpg';
const samanyaPsychic = 'sfw/paintings/samanyaPsychic.jpg';
const ruralPawb = 'sfw/paintings/ruralPawb.jpg';
const zaneTanuki = 'sfw/paintings/zaneTanuki.jpg';
// NSFW sketches
const asterTricoSketch = 'nsfw/sketches/asterTricoSketch.jpg';
const haneVersions = 'nsfw/sketches/haneVersions.jpg';
const jacuzziSketch = 'nsfw/sketches/jacuzziSketch.jpg';
const tricoHammerSketch = 'nsfw/sketches/tricoHammerSketch.jpg';
const spidergirl = 'nsfw/sketches/spidergirl.jpg';
const saniyaTricoSketch = 'nsfw/sketches/saniyaTricoSketch.jpg';
const alphadogSketch = 'nsfw/sketches/alphadogSketch.jpg';
const goldenWoofer2 = 'nsfw/sketches/goldenWoofer2.jpg';
const megaDragonessBonerCoom = 'nsfw/sketches/megaDragonessBonerCoom.jpg';
const megaDragonessPussy = 'nsfw/sketches/megaDragonessPussy.jpg';
const megaDragonessCock = 'nsfw/sketches/megaDragonessCock.jpg';
const donderDickbulge = 'nsfw/sketches/donderDickbulge.jpg';
const angelaGenderbendBoxers = 'nsfw/sketches/angelaGenderbendBoxers.jpg';
// NSFW paintings
const jiangSamanyaConditioning = 'nsfw/paintings/jiangSamanyaConditioning.jpg';
const penelopeOutdoorSpeedpaint = 'nsfw/paintings/penelopeOutdoorSpeedpaint.jpg';
const goldieSauna = 'nsfw/paintings/goldieSauna.jpg';
const hanakoPainting = 'nsfw/paintings/hanakoPainting.jpg';
const jiangSamanyaSlaveTouchup = 'nsfw/paintings/jiangSamanyaSlaveTouchup.jpg';
const quattroAndrewPaint = 'nsfw/paintings/quattroAndrewPaint.jpg';
const zaneTanukiBulge = 'nsfw/paintings/zaneTanukiBulge.jpg';


export const imageCollectionStrings = {
  PLACEHOLDER,
  RANOA_ICON,
  ASTRID_SKETCH,
  FLOOFY_COLORED_LINEART,
  FOUREN_PAINT,
  HEATHER_FIRETAIL_SKETCH,
  KOTA_DEITER,
  LOVE_ARIELLE_SKETCH,
  AVAH_PORTRAIT,
  DELILAH_SKETCH,
  DON_POSTER_SKETCH,
  MESSY_GIRL_COMPLETE,
  ALURIA_HALF_TEXTURE,
  DUSTER_SKETCH_WHITE,
};

export const SFWsketchStrings = {
  EVERETT_SPELL,
  ALPHA_DOG,
  ALEX_SKETCH,
  SPACECAMP_ASHERUTE,
  GIRL_PRACTICE_SKETCH,
  LILY_STATUE,
  RORI,
  evrartSketch,
  IsabelleFiretailColored,
  sketchPracticePortraits2,
  spacecampFlasherPlaneSketch,
  yumiSketch,
  towSharkLine,
  goldenWoofer,
  snowblindWitch,
  sketchPracticePortrait,
  lovetaDressSketch,
  IsabelleFiretailSketch,
  cowboySketch,
};

export const SFWpaintStrings = {
  LOVETA_DRESS,
  MORNING_MARKET_CHASE,
  kotaRainPawPaint,
  glowMoff,
  spacecampFlasher,
  samanyaPsychic,
  ruralPawb,
  zaneTanuki,
};

export const NSFWsketchStrings = {
  asterTricoSketch,
  haneVersions,
  jacuzziSketch,
  tricoHammerSketch,
  spidergirl,
  saniyaTricoSketch,
  alphadogSketch,
  goldenWoofer2,
  megaDragonessBonerCoom,
  megaDragonessPussy,
  megaDragonessCock,
  donderDickbulge,
  angelaGenderbendBoxers,
};

export const NSFWpaintStrings = {
  jiangSamanyaConditioning,
  penelopeOutdoorSpeedpaint,
  goldieSauna,
  hanakoPainting,
  jiangSamanyaSlaveTouchup,
  quattroAndrewPaint,
  zaneTanukiBulge,
};
