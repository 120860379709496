import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import CommissionPrices from "./pages/CommissionPrices";
import TermsOfService from "./pages/TermsOfService";
import SubmissionForm from "./pages/SubmissionForm";
import SFWSampleGallery from "./pages/SFWSampleGallery";
import NSFWSampleGallery from "./pages/NSFWSampleGallery";
import "./App.css";

const App = () => (
  <div className="App">
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" Component={CommissionPrices} />
        <Route path="/tos" Component={TermsOfService} />
        <Route path="/submission" Component={SubmissionForm} />
        <Route path="/sfw-samples" Component={SFWSampleGallery} />
        <Route path="/nsfw-samples" Component={NSFWSampleGallery} />
      </Routes>
    </Router>
  </div>
);

export default App;
