import ImageGallery from "../components/ImageGallery";
import {
  imageCollectionStrings,
  SFWsketchStrings,
  SFWpaintStrings,
} from "../images";
import { StyledDrawerSpacer } from "../styles";

const {
  AVAH_PORTRAIT,
  DELILAH_SKETCH,
  DON_POSTER_SKETCH,
  MESSY_GIRL_COMPLETE,
  ALURIA_HALF_TEXTURE,
  DUSTER_SKETCH_WHITE,
} = imageCollectionStrings;

const {
  EVERETT_SPELL,
  ALPHA_DOG,
  ALEX_SKETCH,
  SPACECAMP_ASHERUTE,
  GIRL_PRACTICE_SKETCH,
  LILY_STATUE,
  RORI,
  evrartSketch,
  IsabelleFiretailColored,
  sketchPracticePortraits2,
  spacecampFlasherPlaneSketch,
  yumiSketch,
  towSharkLine,
  goldenWoofer,
  snowblindWitch,
  sketchPracticePortrait,
  lovetaDressSketch,
  IsabelleFiretailSketch,
  cowboySketch,
} = SFWsketchStrings;

const {
  LOVETA_DRESS,
  MORNING_MARKET_CHASE,
  kotaRainPawPaint,
  glowMoff,
  spacecampFlasher,
  samanyaPsychic,
  ruralPawb,
  zaneTanuki,
} = SFWpaintStrings;

const imageMap = [
  { image: yumiSketch, altText: "yumiSketch" },
  { image: RORI, altText: "RORI" },
  { image: DON_POSTER_SKETCH, altText: "Don_poster_sketch" },
  { image: MESSY_GIRL_COMPLETE, altText: "Messy_girl_complete" },
  { image: ALURIA_HALF_TEXTURE, altText: "aluria_half_texture" },
  { image: DUSTER_SKETCH_WHITE, altText: "duster_sketch_2_white" },
  { image: EVERETT_SPELL, altText: "EVERETT_SPELL" },
  { image: ALEX_SKETCH, altText: "ALEX_SKETCH" },
  { image: SPACECAMP_ASHERUTE, altText: "SPACECAMP_ASHERUTE" },
  { image: GIRL_PRACTICE_SKETCH, altText: "GIRL_PRACTICE_SKETCH" },
  { image: LOVETA_DRESS, altText: "LOVETA_DRESS" },
  { image: IsabelleFiretailColored, altText: "IsabelleFiretailColored" },
  { image: sketchPracticePortraits2, altText: "sketchPracticePortraits2" },
  { image: sketchPracticePortrait, altText: "sketchPracticePortrait" },
  { image: goldenWoofer, altText: "goldenWoofer" },
  { image: lovetaDressSketch, altText: "lovetaDressSketch" },
  { image: IsabelleFiretailSketch, altText: "IsabelleFiretailSketch" },
  { image: AVAH_PORTRAIT, altText: "Avah_portrait" },
  { image: cowboySketch, altText: "cowboySketch" },
  { image: DELILAH_SKETCH, altText: "Delilah_sketch" },
  { image: samanyaPsychic, altText: "samanyaPsychic" },
  { image: zaneTanuki, altText: "zaneTanuki" },
  { image: evrartSketch, altText: "evrartSketch", thicc: true },
  { image: kotaRainPawPaint, altText: "kotaRainPawPaint", thicc: true },
  { image: glowMoff, altText: "glowMoff", thicc: true },
  { image: spacecampFlasher, altText: "spacecampFlasher", thicc: true },
  { image: ruralPawb, altText: "ruralPawb", thicc: true },
  { image: snowblindWitch, altText: "snowblindWitch", thicc: true },
  { image: towSharkLine, altText: "towSharkLine", thicc: true },
  {
    image: spacecampFlasherPlaneSketch,
    altText: "spacecampFlasherPlaneSketch",
    thicc: true,
  },
  { image: ALPHA_DOG, altText: "ALPHA_DOG", thicc: true },
  { image: LILY_STATUE, altText: "LILY_STATUE", thicc: true },
  { image: MORNING_MARKET_CHASE, altText: "MORNING_MARKET_CHASE", thicc: true },
];

const SFWSampleGallery = () => (
  <StyledDrawerSpacer>
    <ImageGallery images={imageMap} />
  </StyledDrawerSpacer>
);

export default SFWSampleGallery;
