import { useState } from "react";
import Divider from "@mui/material/Divider";
import { StyledLink } from "../styles";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const navigationOptions = [
    {
      text: "Commission prices",
      link: "/",
    },
    {
      text: "Terms of Service",
      link: "/tos",
    },
    {
      text: "Submission Form",
      link: "/submission",
    },
    {
      text: "SFW Gallery",
      link: "/sfw-samples",
    },
    {
      text: "NSFW Gallery",
      link: "/nsfw-samples",
    },
    {
      text: "Socials",
      link: "https://linktr.ee/RanoaArt",
      target: "_blank",
    },
    {
      text: "Discord",
      link: "https://discord.gg/weCR9U96ah",
      target: "_blank",
    },
  ];

  const StyledDrawer = styled(Drawer)`
    .MuiPaper-root {
      background-color: lightskyblue;
      color: rgb(84, 84, 84);
    }

    .signature {
      display: flex;
      justify-content: center;
      padding-bottom: 48px;
    }
  `;

  const drawer = (
    <>
      <Toolbar />
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <List>
          {navigationOptions.map((option, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ paddingBottom: "4px", paddingTop: "4px" }}
            >
              <ListItemButton>
                <StyledLink
                  to={option.link}
                  target={option.target}
                  rel="noopener noreferrer"
                >
                  {option.text}
                </StyledLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div className="signature">
          Website by
          <Link
            to="https://lyndsi.dev/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingLeft: "4px" }}
          >
            Lyndsi
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          width: { sm: `calc(100% - 192px)` },
          ml: { sm: `192px` },
          backgroundColor: "lightskyblue",
          color: "rgb(84, 84, 84)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
            Art commissions by Ranoa
          </Typography>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen(!mobileOpen)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 192 },
        }}
      >
        {drawer}
      </StyledDrawer>
      <StyledDrawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 192 },
        }}
        open
      >
        {drawer}
      </StyledDrawer>
    </>
  );
};

export default Navbar;
